



















































































































































































































































































































import axios, { AxiosError } from 'axios'
import Avatar from 'vue-avatar'
import { TranslateResult } from 'vue-i18n'
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { Validation, validationMixin } from 'vuelidate'
import { minLength, required, email } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import store from '@/store'
import {
  ElsaError,
  OmatTiedotLomake,
  Yliopisto,
  Kayttajatiedot,
  KayttajaYliopistoErikoisalat
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { phoneNumber } from '@/utils/constants'
import { getTitleFromAuthorities } from '@/utils/functions'
import { toastFail, toastSuccess } from '@/utils/toast'

@Component({
  components: {
    Avatar,
    ElsaButton,
    ElsaFormError,
    ElsaFormGroup,
    ElsaFormMultiselect
  }
})
export default class OmatTiedot extends Mixins(validationMixin) {
  @Prop({ required: false, default: false })
  editing!: boolean
  needEmailConfirm = false
  confirmEmailValue = ''

  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        confirmEmail: {
          emailConfirmed: () => {
            return this.needEmailConfirm && this.form.email === this.confirmEmailValue
          }
        },
        phoneNumber: {
          phoneNumber
        },
        kayttajanYliopistotJaErikoisalat: {
          $each: {
            yliopisto: {
              id: {
                required
              }
            },
            erikoisalat: {
              required,
              minLength: minLength(1)
            }
          }
        }
      }
    }
  }

  form: OmatTiedotLomake = {
    nimike: null,
    email: null,
    phoneNumber: null,
    avatar: null,
    avatarUpdated: false,
    kayttajanYliopistotJaErikoisalat: []
  }
  params = {
    saving: false
  }

  kayttajaTiedot: Kayttajatiedot | null = null
  valittuYliopisto: Yliopisto | null = null

  async mounted() {
    this.kayttajaTiedot = (await axios.get('/kayttaja-lisatiedot')).data
    this.form = this.initForm()
  }

  selectAvatar() {
    const inputEl = this.$refs['avatar-file-input'] as HTMLInputElement
    inputEl.click()
  }

  removeAvatar() {
    this.form.avatar = null
    this.form.avatarUpdated = true
    const inputEl = this.$refs['avatar-file-input'] as HTMLInputElement
    inputEl.value = ''
  }

  avatarChange(e: Event) {
    const inputElement = e.target as HTMLInputElement
    if (inputElement.files && inputElement.files?.length > 0) {
      const file = inputElement.files[0]
      this.form.avatar = file
      this.form.avatarUpdated = true
    }
  }

  initForm(): OmatTiedotLomake {
    return {
      email: this.account?.email || null,
      phoneNumber: this.account?.phoneNumber || null,
      avatar: this.account?.avatar || null,
      avatarUpdated: false,
      nimike: this.kayttajaTiedot?.nimike || null,
      kayttajanYliopistotJaErikoisalat:
        this.kayttajaTiedot?.kayttajanYliopistotJaErikoisalat || []
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateKayttajanYliopistoState(index: number) {
    const { $dirty, $error } = this.$v.form?.kayttajanYliopistotJaErikoisalat?.$each[index]
      ?.yliopisto.id as Validation
    return $dirty ? ($error ? false : null) : null
  }

  validateKayttajanYliopistoErikoisalaState(index: number) {
    const { $dirty, $error } = this.$v.form?.kayttajanYliopistotJaErikoisalat?.$each[index]
      ?.erikoisalat as Validation
    return $dirty ? ($error ? false : null) : null
  }

  addYliopistoErikoisala() {
    this.form.kayttajanYliopistotJaErikoisalat.push({
      yliopisto: { nimi: '', erikoisalat: [] },
      erikoisalat: []
    })
  }

  deleteYliopistoErikoisala(index: number) {
    if (this.form.kayttajanYliopistotJaErikoisalat) {
      Vue.delete(this.form.kayttajanYliopistotJaErikoisalat, index)
    }
  }

  yliopistoLabel(yliopisto: Yliopisto): TranslateResult {
    return yliopisto.nimi === '' ? '' : this.$t(`yliopisto-nimi.${yliopisto.nimi}`)
  }

  async onSubmit() {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) {
      return
    }

    try {
      this.params.saving = true
      await store.dispatch(
        'auth/putUser',
        // Ohitetaan olemassa olevan avatarin lähettäminen
        !this.form.avatar?.name
          ? {
              ...this.form,
              avatar: null
            }
          : this.form
      )
      toastSuccess(this, this.$t('omat-tiedot-paivitetty'))
      this.$v.form.$reset()
      this.kayttajaTiedot = (await axios.get('/kayttaja-lisatiedot')).data
      this.form = this.initForm()
      this.$emit('change', false)
    } catch (err) {
      const axiosError = err as AxiosError<ElsaError>
      const message = axiosError?.response?.data?.message
      toastFail(
        this,
        message
          ? `${this.$t('omien-tietojen-paivittaminen-epaonnistui')}: ${this.$t(message)}`
          : this.$t('omien-tietojen-paivittaminen-epaonnistui')
      )
    } finally {
      this.params.saving = false
    }
  }

  async onCancel() {
    if (await confirmExit(this)) {
      this.form = this.initForm()
      this.$v.form.$reset()
      this.$emit('change', false)
    }
  }

  get previewSrc() {
    if (this.form.avatar && this.form.avatar.name) {
      return URL.createObjectURL(this.form.avatar)
    } else if (this.form.avatar) {
      return `data:image/jpeg;base64,${this.account.avatar}`
    }
    return undefined
  }

  get account() {
    return store.getters['auth/account']
  }

  get displayName() {
    if (this.account) {
      return `${this.account.firstName} ${this.account.lastName}`
    }
    return ''
  }

  get avatarSrc() {
    if (this.account) {
      return `data:image/jpeg;base64,${this.account.avatar}`
    }
    return undefined
  }

  get activeAuthority() {
    if (this.account) {
      return this.account.activeAuthority
    }
    return ''
  }

  get title() {
    return getTitleFromAuthorities(this, this.activeAuthority)
  }

  get avatar() {
    if (this.account) {
      return this.account.avatar
    }
    return null
  }

  yliopistotOptions(yliopisto: Yliopisto) {
    return this.kayttajaTiedot?.yliopistot?.map((y: Yliopisto) => {
      if (
        this.form.kayttajanYliopistotJaErikoisalat
          .map((y2: KayttajaYliopistoErikoisalat) => y2.yliopisto.id)
          .includes(y.id)
      ) {
        return {
          ...y,
          $isDisabled: y.id !== yliopisto.id
        }
      }
      return y
    })
  }
}
